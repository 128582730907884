import React from 'react';

export const HTMLContent = ({ content, className }) => (
  <div
    className={className}
    style={{ fontSize: 16 }}
    dangerouslySetInnerHTML={{ __html: content }}
  />
);

const Content = ({ content, className }) => (
  <div className={className} style={{ fontSize: 16 }}>
    {content}
  </div>
);

export default Content;
