import React from 'react';
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import { Row, Col } from 'antd';
import Img from 'gatsby-image';

import Content, { HTMLContent } from '../components/Content';
import Layout from '../components/Layout';
import { TagList } from '../components/TagList';

export const BlogPostTemplate = ({
  helmet,
  title,
  createdAt,
  categories,
  featuredImage,
  scripture,
  translation,
  location,
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content;
  return (
    <Layout title={`${title} | I Profess Daily`}>
      <Row style={{ marginTop: 48 }}>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 12, offset: 6 }}
          xl={{ span: 12, offset: 6 }}
          xxl={12}
          style={{
            marginBottom: 48,
            backgroundColor: '#fff',
            boxShadow: '0 1px 12px rgba(0,0,0,0.1)',
          }}
        >
          {featuredImage ? (
            <Img
              fluid={featuredImage?.childImageSharp?.fluid || featuredImage}
              alt={`${title}`}
            />
          ) : (
            <p>No image</p>
          )}
          <div style={{ padding: 24 }}>
            <h1 style={{ margin: 0 }}>{title}</h1>
            <h4 style={{ color: '#aaa' }}>{createdAt.toString()}</h4>
            <p
              style={{
                fontSize: 20,
                padding: 12,
                borderLeft: '3px solid',
                marginTop: 24,
                marginBottom: 24,
              }}
            >
              {scripture}
            </p>
            <div style={{ textAlign: 'right', marginBottom: 24 }}>
              <p style={{ fontWeight: '700' }}>
                {location}
                {translation ? `, ${translation}` : null}
              </p>
            </div>
            <PostContent content={content} />
            <TagList tags={categories} />
          </div>
        </Col>
      </Row>
      <Row style={{ marginTop: 48 }}>
        <Col
          xs={{ span: 20, offset: 2 }}
          sm={{ span: 20, offset: 2 }}
          md={{ span: 20, offset: 2 }}
          lg={{ span: 10, offset: 7 }}
          xl={{ span: 10, offset: 7 }}
          xxl={12}
          style={{ marginBottom: 48, textAlign: 'center' }}
        >
          <Link to="/">Back to all verses</Link>
        </Col>
      </Row>
    </Layout>
  );
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <BlogPostTemplate
      helmet={
        <Helmet titleTemplate="%s | Blog">
          <title>{`${post.frontmatter.title}`}</title>
        </Helmet>
      }
      title={post.frontmatter.title}
      createdAt={post.frontmatter.createdAt}
      featuredImage={post.frontmatter.featuredImage}
      categories={post.frontmatter.categories}
      scripture={post.frontmatter.scripture}
      location={post.frontmatter.location}
      translation={post.frontmatter.translation}
      content={post.html}
      contentComponent={HTMLContent}
    />
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        createdAt(formatString: "MMMM DD, YYYY")
        title
        scripture
        translation
        location
        categories
        featuredImage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
